import type { AxiosError } from "axios";
import { isString } from "lodash";
import Router from "next/router";
import type { ErrorResponse } from "types";

const handleRequestError = (error: AxiosError<ErrorResponse>): void => {
  if (error.response?.status === 401) {
    Router.push("/login");
  }
  if (error.response?.status === 403) {
    Router.push("/unauthorised");
  }
  if (error.response?.status === 404) {
    Router.push("/404");
  }
};

export const getErrorMessage = (errorResponse): string => {
  return isString(errorResponse.data)
    ? errorResponse.data
    : errorResponse.data.message;
};

export default handleRequestError;
