import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { getShouldNotRetry } from "helpers/auth";
import type { QueryResult} from "react-query";
import { useQuery } from "react-query";
import type { ErrorResponse } from "types";
import type { ActivityGroupSalesData } from "types/model/activity-group";
import handleRequestError from "./helpers/handleRequestError";

const getActivityGroupSales = async (
  _,
  id: string,
  includeCancelled: boolean
): Promise<ActivityGroupSalesData> => {
  if (!id || id === "undefined") return;
  const response: AxiosResponse<ActivityGroupSalesData> = await axios.get(
    `/api/activity-groups/${id}/${
      includeCancelled ? "sales-including-cancelled" : "sales"
    }`
  );
  return response.data;
};

export default function useActivityGroupSales(
  id: string,
  includeCancelled = false
): QueryResult<ActivityGroupSalesData, AxiosError<ErrorResponse>> {
  return useQuery(
    ["activity-group-sales", id, includeCancelled],
    getActivityGroupSales,
    {
      retry: (failureCount: number, error) => {
        return getShouldNotRetry(error.response?.status)
          ? false
          : failureCount < 3;
      },
      onError: error => {
        handleRequestError(error);
      }
    }
  );
}
