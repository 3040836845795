import type { Schema } from "@data-driven-forms/react-form-renderer";
import {
  compareAsc,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getDate,
  isAfter,
  isBefore,
  isValid,
  startOfDay,
  startOfMonth
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { convertAmountBasedOnCurrency } from "helpers/currency";
import { formatDate, getStartOfNextMonth } from "helpers/date";
import { formatCurrency } from "helpers/helpers";
import type { Client } from "types/model/client";
import type {
  FixedPaymentScheduleFormDataItem,
  PaymentSchedule,
  PaymentScheduleItem,
  SubscriptionPlan,
  SubscriptionPlanFormData,
  SubscriptionPlanFormDataToSubmit
} from "types/model/subscription-plan";
import {
  PaymentScheduleFrequency,
  PaymentScheduleItemStatus,
  PaymentScheduleType
} from "types/model/subscription-plan";

export const getSubscriptionNameFromId = (
  subscriptionPlanId: string,
  subscriptionsPlans: SubscriptionPlan[]
): string => {
  const subscriptionPlan = subscriptionsPlans.find(
    subscriptionPlan => subscriptionPlan._id === subscriptionPlanId
  );
  return subscriptionPlan?.name;
};

export const getRegularPaymentScheduleDescription = (
  paymentSchedule: PaymentSchedule,
  client: Client
) => {
  if (paymentSchedule.type !== PaymentScheduleType.Regular) {
    return "";
  }

  const frequencyText =
    paymentSchedule.frequency === PaymentScheduleFrequency.Monthly
      ? "month"
      : ""; // will need to update if we ever support other frequencies

  const amountFormatted = formatCurrency({
    rawAmount: paymentSchedule.amount,
    currency: client.currency
  });

  return `${amountFormatted}/${frequencyText}`;
};

export const getRegularPaymentScheduleStartDescriptionForSiteDisplay = (
  paymentSchedule: PaymentSchedule,
  hasInitialPayment: boolean,
  client: Client
) => {
  const startOfNextMonth = getStartOfNextMonth(client.timeZone);

  const hasFirstPaymentDatePassed = isBefore(
    new Date(paymentSchedule.firstPaymentDate),
    new Date()
  );

  const firstPaymentDate = hasFirstPaymentDatePassed
    ? startOfNextMonth
    : paymentSchedule.firstPaymentDate;

  return `${hasInitialPayment ? "Next" : "First"} payment on ${formatDate(
    firstPaymentDate,
    client.dateFormat,
    client.timeZone
  )}`;
};

export const getRegularPaymentScheduleEndDescription = (
  paymentSchedule: PaymentSchedule,
  client: Client
) => {
  if (paymentSchedule.finalPaymentDate) {
    return `Final payment on ${formatDate(
      paymentSchedule.finalPaymentDate,
      client.dateFormat,
      client.timeZone
    )}`;
  } else {
    return "Continues until cancelled";
  }
};

export const getFixedPaymentScheduleDescription = (
  paymentSchedule: PaymentSchedule,
  client: Client
) => {
  if (paymentSchedule.type !== PaymentScheduleType.Fixed) {
    return "";
  }

  const now = new Date();
  const nextPayment = paymentSchedule.schedule
    .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
    .find(item => isAfter(new Date(item.date), now));

  if (!nextPayment) {
    return "";
  }

  const amountFormatted = formatCurrency({
    rawAmount: nextPayment.amount,
    currency: client.currency
  });

  const dateFormatted = formatDate(
    nextPayment.date,
    client.dateFormat,
    client.timeZone
  );

  return `Next payment: ${amountFormatted} on ${dateFormatted}`;
};

export const getPaymentScheduleItemStatus = (
  paymentScheduleItem: PaymentScheduleItem,
  allPaymentScheduleItems: PaymentScheduleItem[]
) => {
  const now = new Date();
  const paymentScheduleItemDate = paymentScheduleItem.date;

  if (isBefore(paymentScheduleItemDate, now)) {
    return PaymentScheduleItemStatus.Completed;
  }

  const futurePayments = allPaymentScheduleItems.filter(item =>
    isAfter(item.date, now)
  );

  // Sort future payments in ascending order
  futurePayments.sort((a, b) => compareAsc(a.date, b.date));

  // Get the next payment
  const nextPayment = futurePayments[0];

  if (nextPayment && nextPayment._id === paymentScheduleItem._id) {
    return PaymentScheduleItemStatus.NextPayment;
  }

  return PaymentScheduleItemStatus.Upcoming;
};

interface GetSubscriptionPlanFormDataForSubmissionParams {
  formData: SubscriptionPlanFormData;
  formSchema: Schema;
  client: Client;
}

export const getSubscriptionPlanFormDataForSubmission = ({
  formData,
  formSchema,
  client
}: GetSubscriptionPlanFormDataForSubmissionParams): SubscriptionPlanFormDataToSubmit => {
  const currencyFields = formSchema.fields.filter(
    field => field.component === "currency"
  );

  const formDataToSubmit: SubscriptionPlanFormDataToSubmit = {
    ...formData,
    fixedPaymentSchedule: undefined,
    userCancellationsMinNoticePeriod: parseInt(
      formData.userCancellationsMinNoticePeriod,
      10
    )
  };

  for (const currencyField of currencyFields) {
    const fieldName = currencyField.name;
    if (formDataToSubmit[fieldName]) {
      formDataToSubmit[fieldName] = convertAmountBasedOnCurrency(
        formDataToSubmit[fieldName],
        client.currency
      );
    }
  }

  if (formData.fixedPaymentSchedule) {
    formDataToSubmit.fixedPaymentSchedule = formData.fixedPaymentSchedule.map(
      item => {
        return {
          date: item.date,
          amount: convertAmountBasedOnCurrency(item.amount, client.currency)
        };
      }
    );
  }

  return formDataToSubmit;
};

export const validateFixedPaymentSchedule = (
  data: FixedPaymentScheduleFormDataItem<number>[]
) => {
  const dateSet = new Set();

  for (let i = 0; i < data.length; i++) {
    // Check if the date is at the start of the month
    const dayOfTheMonth = getDate(data[i].date);
    if (dayOfTheMonth !== 1) {
      return false;
    }

    // Check if the date is duplicated
    const dateString = startOfDay(data[i].date).toISOString();
    if (dateSet.has(dateString)) {
      return false;
    } else {
      dateSet.add(dateString);
    }

    // Check if the amount is zero or greater
    if (data[i].amount < 0) {
      return false;
    }
  }

  return true;
};

export const getFixedPaymentScheduleWithFilledMissingMonths = (
  schedule: FixedPaymentScheduleFormDataItem<number>[],
  timeZone: string
) => {
  if (!schedule || schedule.length === 0) {
    return [];
  }

  // Find the start and end dates of the schedule
  const start = startOfMonth(schedule[0].date);
  const end = endOfMonth(schedule[schedule.length - 1].date);

  // Generate each month within the interval
  const months = eachMonthOfInterval({ start, end });

  // Create a map for easy lookup of amounts by date
  const paymentMap = new Map(
    schedule.map(entry => [format(entry.date, "yyyy-MM-dd"), entry.amount])
  );

  // Fill in the missing months with amount 0
  return months.map(month => {
    const formattedMonth = format(month, "yyyy-MM-dd");
    const amount = paymentMap.get(formattedMonth) || 0;
    const localDate = zonedTimeToUtc(month, timeZone);
    return { date: localDate, amount };
  });
};

export const getIsPaymentDateValid = (date: Date, timeZone: string) => {
  const isDateValid = isValid(date);
  if (!isDateValid) {
    return false;
  }

  const zonedDate = utcToZonedTime(date, timeZone);

  // Check if the date is at the start of the month
  const dayOfTheMonth = getDate(zonedDate);
  if (dayOfTheMonth !== 1) {
    return false;
  }

  return true;
};

export const getUniquePaymentAmountsForSubscriptionPlan = (
  subscriptionPlan: SubscriptionPlan
): number[] => {
  const paymentSchedule = subscriptionPlan.paymentSchedule;
  const paymentAmounts: number[] = [];

  if (paymentSchedule.type === PaymentScheduleType.Regular) {
    paymentAmounts.push(paymentSchedule.amount);
  } else if (paymentSchedule.type === PaymentScheduleType.Fixed) {
    paymentSchedule.schedule.forEach(item => {
      paymentAmounts.push(item.amount);
    });
  }

  // ensure no duplicates
  const paymentAmountsSet = new Set(paymentAmounts);

  // sort in ascending order
  const sortedPaymentAmounts = Array.from(paymentAmountsSet).sort(
    (a, b) => a - b
  );

  return sortedPaymentAmounts;
};

export const getHasSubscriptionPlanFirstPaymentDatePassed = (
  subscriptionPlan: SubscriptionPlan
) => {
  let firstPaymentDate: Date;
  if (subscriptionPlan.paymentSchedule.type === PaymentScheduleType.Regular) {
    firstPaymentDate = new Date(
      subscriptionPlan.paymentSchedule.firstPaymentDate
    );
  } else if (
    subscriptionPlan.paymentSchedule.type === PaymentScheduleType.Fixed
  ) {
    const firstPaymentScheduleItem =
      subscriptionPlan.paymentSchedule.schedule.sort((a, b) =>
        compareAsc(new Date(a.date), new Date(b.date))
      );
    firstPaymentDate = new Date(firstPaymentScheduleItem[0]?.date);
  }
  const now = new Date();
  return isBefore(firstPaymentDate, now);
};

export const getDoesSubscriptionPlanHavePaymentDatesRemaining = (
  subscriptionPlan: SubscriptionPlan
) => {
  const paymentSchedule = subscriptionPlan.paymentSchedule;
  if (paymentSchedule.type === PaymentScheduleType.Regular) {
    return (
      !paymentSchedule.finalPaymentDate ||
      isAfter(new Date(paymentSchedule.finalPaymentDate), new Date())
    );
  } else if (paymentSchedule.type === PaymentScheduleType.Fixed) {
    const areAnyPaymentDatesAfterNow = paymentSchedule.schedule.some(item =>
      isAfter(new Date(item.date), new Date())
    );
    return areAnyPaymentDatesAfterNow;
  }
};

export const getCanSubscriptionPlanBeAddedToNewTicket = (
  subscriptionPlan: SubscriptionPlan
) => {
  const doesSubscriptionTicketHavePaymentDatesRemaining =
    getDoesSubscriptionPlanHavePaymentDatesRemaining(subscriptionPlan);

  const shouldDisableAfterFirstPaymentAndDateHasPassed =
    subscriptionPlan.disableAfterFirstPaymentDate &&
    getHasSubscriptionPlanFirstPaymentDatePassed(subscriptionPlan);

  return (
    doesSubscriptionTicketHavePaymentDatesRemaining &&
    !shouldDisableAfterFirstPaymentAndDateHasPassed
  );
};
