import type { Schema } from "@data-driven-forms/react-form-renderer";
import { convertAmountBasedOnCurrency } from "helpers/currency";
import { getDateEndOfDay, getDateStartOfDay } from "helpers/date";
import { cloneDeep, omit } from "lodash";
import type { ActivityGroup } from "types/model/activity-group";
import type { Client } from "types/model/client";
import type {
  DiscountRule,
  DiscountRuleFormData,
  MultiActivityCondition,
  MultiSessionCondition
} from "types/model/discount-rule";
import {
  DiscountCodeUsageType,
  DiscountRuleSessionsOption,
  DiscountRuleType,
  DiscountRuleUnit,
  MultiPurchaseDiscountRuleType
} from "types/model/discount-rule";

const discountRuleOrder = [
  DiscountRuleType.MultiPurchase,
  DiscountRuleType.MultiAttendee,
  DiscountRuleType.Code
];

export const getDiscountRuleTypeReadableName = (
  discountRuleType: DiscountRuleType
) => {
  switch (discountRuleType) {
    case DiscountRuleType.Code:
      return "Discount code";
    case DiscountRuleType.MultiAttendee:
      return "Multi Attendee rule";
    case DiscountRuleType.MultiPurchase:
      return "Multi Purchase rule";
    default:
      console.error("Invalid Discount Rule Type");
      return "";
  }
};

export const getDiscountRuleTypeReadableNameShort = (
  discountRuleType: DiscountRuleType
) => {
  switch (discountRuleType) {
    case DiscountRuleType.Code:
      return "Discount code";
    case DiscountRuleType.MultiAttendee:
      return "Multi Attendee";
    case DiscountRuleType.MultiPurchase:
      return "Multi Purchase";
    default:
      console.error("Invalid Discount Rule Type");
      return "";
  }
};

export const getDiscountRuleTypeOrCode = (
  discountRuleType: DiscountRuleType
) => {
  switch (discountRuleType) {
    case DiscountRuleType.Code:
      return "Discount code";
    case DiscountRuleType.MultiAttendee:
    case DiscountRuleType.MultiPurchase:
      return "Discount rule";
    default:
      console.error("Invalid Discount Rule Type");
      return "";
  }
};

export const getDiscountRuleForCheckoutDisplay = (
  discountRule: DiscountRule
) => {
  switch (discountRule.type) {
    case DiscountRuleType.MultiAttendee:
      return "MULTI ATTENDEE";
    case DiscountRuleType.MultiPurchase:
      return "MULTI PURCHASE";
    case DiscountRuleType.Code:
      return discountRule.code;
    default:
      console.error("Invalid Discount Rule Type");
      return "";
  }
};

export const getIsDiscountRuleTypeCode = (discountRule: DiscountRule) => {
  return discountRule.type === DiscountRuleType.Code;
};

export const getIsDiscountRuleTypeMultiAttendee = (
  discountRule: DiscountRule
) => {
  return discountRule.type === DiscountRuleType.MultiAttendee;
};

export const getIsDiscountRuleTypeMultiPurchase = (
  discountRule: DiscountRule
) => {
  return discountRule.type === DiscountRuleType.MultiPurchase;
};

interface GetDiscountFormDataForSubmissionData {
  formData: DiscountRuleFormData;
  formSchema: Schema;
  prefix?: string;
  inModal?: boolean;
  client: Client;
}

export const getDiscountFormDataForSubmission = ({
  formData,
  formSchema,
  prefix = "",
  inModal = false,
  client
}: GetDiscountFormDataForSubmissionData): DiscountRuleFormData<string[]> => {
  const currencyFields = formSchema.fields.filter(
    field => field.component === "currency"
  );

  const formDataToSubmit: DiscountRuleFormData<string[]> = omit(formData, [
    "activityGroups"
  ]);

  for (const currencyField of currencyFields) {
    const fieldName = currencyField.name.replace(`${prefix}.`, "");
    if (formDataToSubmit[fieldName]) {
      formDataToSubmit[fieldName] = convertAmountBasedOnCurrency(
        formDataToSubmit[fieldName],
        client.currency
      );
    }
  }

  if (inModal && formData.type === DiscountRuleType.MultiPurchase) {
    const fieldKey =
      formData.multiPurchaseType === MultiPurchaseDiscountRuleType.Activity
        ? "multiActivityConditions"
        : "multiSessionConditions";

    const conditions = cloneDeep(formDataToSubmit[fieldKey]);

    for (const condition of conditions) {
      if ("minimumSessions" in condition) {
        condition.minimumSessions = parseInt(String(condition.minimumSessions));
      }

      if (condition.fixedAmount) {
        condition.fixedAmount = convertAmountBasedOnCurrency(
          condition.fixedAmount,
          client.currency
        );
      }
      if ("amountPerSession" in condition) {
        condition.amountPerSession = convertAmountBasedOnCurrency(
          condition.amountPerSession,
          client.currency
        );
      }

      if (condition.percentage) {
        condition.percentage = parseFloat(String(condition.percentage));
      }
    }

    formDataToSubmit[fieldKey] = conditions as MultiActivityCondition[] &
      MultiSessionCondition[];
  }

  const activityGroups = formData.activityGroups || [];
  formDataToSubmit.activityGroups = activityGroups.map(
    activityGroup => activityGroup._id
  );

  return formDataToSubmit;
};

export const getDiscountSessionsOptionReadableName = (
  sessionsOption: DiscountRuleSessionsOption
) => {
  switch (sessionsOption) {
    case DiscountRuleSessionsOption.SameActivity:
      return "Must be from same activity";
    case DiscountRuleSessionsOption.MultipleActivities:
      return "Can be from multiple activities";
    default:
      console.error("Invalid Sessions Option");
      return "";
  }
};

export const getDiscountCodeUsageReadableName = (
  discountCodeUsageType: DiscountCodeUsageType
) => {
  switch (discountCodeUsageType) {
    case DiscountCodeUsageType.AllActivities:
      return "All activities";
    case DiscountCodeUsageType.SelectedActivities:
      return "Selected activities";
    default:
      console.error("Invalid Discount Code Usage Type");
      return "";
  }
};

export const discountRuleQueryPopulation = {
  path: "activityGroups",
  populate: [
    {
      path: "fieldData.field fieldData.valueRefVenue"
    },
    {
      path: "activities",
      options: { sort: { "date.start": "asc" } }
    }
  ]
};

export const sortDiscountRuleLineItems = (a, b) => {
  return (
    discountRuleOrder.indexOf(a.discountRule.type) -
    discountRuleOrder.indexOf(b.discountRule.type)
  );
};

export const sortDiscountRules = (a: DiscountRule, b: DiscountRule) => {
  return discountRuleOrder.indexOf(a.type) - discountRuleOrder.indexOf(b.type);
};

export const getMultiSessionConditionsDataToSave = (
  multiSessionConditions: MultiSessionCondition[]
) => {
  if (!multiSessionConditions?.length) {
    return [];
  }

  const multiSessionConditionsDataToSave = multiSessionConditions.map(item => ({
    unit: item.unit,
    ...(item.unit === DiscountRuleUnit.FixedAmount && {
      fixedAmount: item.fixedAmount
    }),
    ...(item.unit === DiscountRuleUnit.Percentage && {
      percentage: item.percentage
    }),
    ...(item.unit === DiscountRuleUnit.AmountPerSession && {
      amountPerSession: item.amountPerSession
    }),
    minimumSessions: item.minimumSessions
  }));

  return multiSessionConditionsDataToSave;
};

export const getMultiActivityConditionsDataToSave = (
  multiActivityConditions: MultiActivityCondition[]
) => {
  if (!multiActivityConditions?.length) {
    return [];
  }

  const multiActivityConditionsDataToSave = multiActivityConditions.map(
    item => ({
      unit: item.unit,
      ...(item.unit === DiscountRuleUnit.FixedAmount && {
        fixedAmount: item.fixedAmount
      }),
      ...(item.unit === DiscountRuleUnit.Percentage && {
        percentage: item.percentage
      }),
      minimumActivities: item.minimumActivities
    })
  );
  return multiActivityConditionsDataToSave;
};

type GetDiscountRuleDataForUpdateData = {
  reqBody: DiscountRuleFormData<ActivityGroup[]>;
  type: DiscountRuleType;
  canUpdateConfigData: boolean;
  client: Client;
};

export const getDiscountRuleDataForUpdate = ({
  reqBody,
  type,
  canUpdateConfigData,
  client
}: GetDiscountRuleDataForUpdateData) => {
  const {
    name,
    dates,
    redemptionLimit,
    activityGroups,
    minimumTotal,
    maximumDiscountAmount,
    codeUsage,
    fixedAmount,
    percentage,
    code,
    unit,
    multiPurchaseType,
    sessionsOption,
    amountAdditionalAttendee,
    percentageAdditionalAttendee,
    multiActivityConditions,
    multiSessionConditions,
    enabled,
    onlyAllowForUserFirstBooking
  } = reqBody;

  const isMultiAttendeeOrMultiPurchaseDiscountRule =
    type === DiscountRuleType.MultiAttendee ||
    type === DiscountRuleType.MultiPurchase;

  const shouldIncludeActivityGroups =
    isMultiAttendeeOrMultiPurchaseDiscountRule ||
    (type === DiscountRuleType.Code &&
      codeUsage === DiscountCodeUsageType.SelectedActivities);

  const baseData = { name, enabled };

  const codeData = {
    code: type === DiscountRuleType.Code ? code : null
  };

  const dataLimitsData = {
    startDate:
      dates?.shouldLimitDatePeriod && dates?.specifyStartDate
        ? getDateStartOfDay(dates.startDate, client.timeZone)
        : null,
    endDate:
      dates?.shouldLimitDatePeriod && dates?.specifyEndDate
        ? getDateEndOfDay(dates.endDate, client.timeZone)
        : null
  };

  const redemptionLimitsData = {
    maxUserRedemptions:
      redemptionLimit?.shouldLimitUserRedemptions &&
      type === DiscountRuleType.Code &&
      parseInt(redemptionLimit.maxUserRedemptions) > 0
        ? parseInt(redemptionLimit.maxUserRedemptions)
        : null,
    maxRedemptions:
      redemptionLimit?.shouldLimitRedemptions &&
      type === DiscountRuleType.Code &&
      parseInt(redemptionLimit.maxRedemptions) > 0
        ? parseInt(redemptionLimit.maxRedemptions)
        : null
  };

  const discountCodeConfigData =
    type === DiscountRuleType.Code
      ? {
          type,
          unit: unit,
          codeUsage,
          minimumTotal: minimumTotal ?? null,
          percentage: unit === DiscountRuleUnit.Percentage ? percentage : null,
          fixedAmount:
            unit === DiscountRuleUnit.FixedAmount ? fixedAmount ?? null : null,
          maximumDiscountAmount:
            unit === DiscountRuleUnit.Percentage
              ? maximumDiscountAmount ?? null
              : null,
          sessionsOption: null,
          amountAdditionalAttendee: null,
          percentageAdditionalAttendee: null,
          multiPurchaseType: null,
          multiSessionConditions: [],
          multiActivityConditions: [],
          onlyAllowForUserFirstBooking
        }
      : {};

  const multiAttendeeRuleConfigData =
    type === DiscountRuleType.MultiAttendee
      ? {
          type,
          unit: unit,
          codeUsage: null,
          minimumTotal: null,
          percentage: null,
          fixedAmount: null,
          maximumDiscountAmount: null,
          sessionsOption,
          onlyAllowForUserFirstBooking,
          ...(unit === DiscountRuleUnit.AmountAdditionalAttendee && {
            amountAdditionalAttendee,
            percentageAdditionalAttendee: null
          }),
          ...(unit === DiscountRuleUnit.PercentageAdditionalAttendee && {
            amountAdditionalAttendee: null,
            percentageAdditionalAttendee
          }),
          multiPurchaseType: null,
          multiSessionConditions: [],
          multiActivityConditions: []
        }
      : {};

  const multiPurchaseRuleConfigData =
    type === DiscountRuleType.MultiPurchase
      ? {
          type,
          unit: null,
          codeUsage: null,
          minimumTotal: null,
          percentage: null,
          fixedAmount: null,
          maximumDiscountAmount: null,
          amountAdditionalAttendee: null,
          percentageAdditionalAttendee: null,
          multiPurchaseType,
          onlyAllowForUserFirstBooking,
          ...(multiPurchaseType === MultiPurchaseDiscountRuleType.Session && {
            multiSessionConditions: getMultiSessionConditionsDataToSave(
              multiSessionConditions || []
            ),
            multiActivityConditions: [],
            sessionsOption
          }),
          ...(multiPurchaseType === MultiPurchaseDiscountRuleType.Activity && {
            multiSessionConditions: [],
            multiActivityConditions: getMultiActivityConditionsDataToSave(
              multiActivityConditions || []
            ),
            sessionsOption: null
          })
        }
      : {};

  const configData = canUpdateConfigData
    ? {
        ...discountCodeConfigData,
        ...multiAttendeeRuleConfigData,
        ...multiPurchaseRuleConfigData
      }
    : {};

  return {
    ...baseData,
    ...codeData,
    ...dataLimitsData,
    ...redemptionLimitsData,
    activityGroups: shouldIncludeActivityGroups ? activityGroups : [],
    ...configData
  };
};
